/* eslint-disable no-underscore-dangle */
import { Image, Text, View } from '@react-pdf/renderer';
import { ProposalPropTypes } from 'components/advisor/proposal/body/sections/types';
import { ACCOUNT_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import { TAX_OPTIONS } from 'constants/accounts';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import ExtraDataSection from 'reports/base/sections/extra';
import { getPositionsTaxonomy, getTaxonomyLevelIterator, setPrecision } from 'utils/utils';
import {
  BENCHMARK_POSITIONS_ANALYSIS_CHART_ID,
  RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID,
  TARGET_POSITIONS_ANALYSIS_CHART_ID
} from '../constants';
import { getLevelPercent, processTaxonomy } from '../utils';
import Row from './row';
import styles from './styles';

const DistributionSummary = ({
  benchmark,
  benchmarkName,
  proposal,
  recommended,
  recommendedName,
  start,
  startingValue,
  target,
  targetName
}) => {
  const {
    collapsedHoldings,
    taxonomies,
    user: {
      advisor: {
        company: {
          expand_asset_classification: expandAssetClassification,
          expand_asset_details: expandAssetDetails
        }
      }
    }
  } = useContext(AdvisorContext);

  const targetTaxonomy = getPositionsTaxonomy(
    target,
    taxonomies,
    expandAssetClassification,
    expandAssetDetails
  );
  const benchmarkTaxonomy = benchmark
    ? getPositionsTaxonomy(benchmark, taxonomies, expandAssetClassification, expandAssetDetails)
    : {};
  const recommendedTaxonomy = recommended
    ? getPositionsTaxonomy(recommended, taxonomies, expandAssetClassification, expandAssetDetails)
    : {};

  const holdingsTable = {};
  processTaxonomy('target', targetTaxonomy, holdingsTable);
  if (benchmark) processTaxonomy('benchmark', benchmarkTaxonomy, holdingsTable);
  if (recommended) processTaxonomy('recommended', recommendedTaxonomy, holdingsTable);

  const targetValue = targetTaxonomy.__value ?? 0;
  const recommendedValue = recommendedTaxonomy.__value ?? 0;
  const benchmarkValue = benchmarkTaxonomy.__value ?? 0;

  return (
    <View>
      <ExtraDataSection proposal={{ start, starting_value: startingValue }} />

      <View style={styles.table} wrap={false}>
        <View style={styles.row}>
          <View style={styles.cell} />
          {target && (
            <View style={[styles.cell, styles.targetHeaderCell]}>
              <Text style={styles.cellText}>{targetName || 'Current Portfolio'}</Text>
            </View>
          )}
          {recommended && (
            <View style={[styles.cell, styles.recommendedHeaderCell]}>
              <Text style={styles.cellText}>{recommendedName}</Text>
            </View>
          )}
          {benchmark && (
            <View style={[styles.cell, styles.benchmarkHeaderCell, { marginRight: 0 }]}>
              <Text style={styles.cellText}>{benchmarkName}</Text>
            </View>
          )}
        </View>
        <View style={styles.row}>
          <View style={styles.cell} />
          {target && (
            <View style={styles.cell}>
              <Image src={proposal[TARGET_POSITIONS_ANALYSIS_CHART_ID]} style={styles.chart} />
            </View>
          )}
          {recommended && (
            <View style={styles.cell}>
              <Image src={proposal[RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID]} style={styles.chart} />
            </View>
          )}
          {benchmark && (
            <View style={styles.cell}>
              <Image src={proposal[BENCHMARK_POSITIONS_ANALYSIS_CHART_ID]} style={styles.chart} />
            </View>
          )}
        </View>
      </View>

      <View style={styles.table}>
        {getTaxonomyLevelIterator(holdingsTable).map(([l1Key, l1], l1Idx) => (
          <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
            <Row
              label={l1Key}
              depth={1}
              target={getLevelPercent(l1.__target_value, targetValue)}
              benchmark={benchmark ? getLevelPercent(l1.__benchmark_value, benchmarkValue) : null}
              recommended={
                recommended ? getLevelPercent(l1.__recommended_value, recommendedValue) : null
              }
            />

            {expandAssetClassification &&
              !collapsedHoldings.includes(l1Key) &&
              getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                  <Row
                    label={l2Key}
                    depth={2}
                    target={getLevelPercent(l2.__target_value, targetValue)}
                    benchmark={
                      benchmark ? getLevelPercent(l2.__benchmark_value, benchmarkValue) : null
                    }
                    recommended={
                      recommended ? getLevelPercent(l2.__recommended_value, recommendedValue) : null
                    }
                  />

                  {expandAssetDetails &&
                    !collapsedHoldings.includes(l2Key) &&
                    getTaxonomyLevelIterator(l2).map(([l3Key, l3], l3Idx) => (
                      <Fragment key={`l3-${l3.__id ?? `${l1Idx}-${l2Idx}-${l3Idx}`}`}>
                        <Row
                          label={l3Key}
                          depth={3}
                          target={getLevelPercent(l3.__target_value, targetValue)}
                          benchmark={
                            benchmark ? getLevelPercent(l3.__benchmark_value, benchmarkValue) : null
                          }
                          recommended={
                            recommended
                              ? getLevelPercent(l3.__recommended_value, recommendedValue)
                              : null
                          }
                        />
                      </Fragment>
                    ))}
                </Fragment>
              ))}
          </Fragment>
        ))}

        <View style={[styles.row, styles.rowLevel1]} wrap={false}>
          <View style={styles.cell}>
            <Text style={styles.cellTextLevel1}>Total Portfolio</Text>
          </View>
          <View style={styles.cell}>
            <Text style={[styles.cellText, styles.cellTextLevel1]}>100%</Text>
          </View>
          {recommended && (
            <View style={[styles.cell, styles.recommendedCell]}>
              <Text style={[styles.cellText, styles.cellTextLevel1, styles.recommendedCellText]} />
              <Text style={[styles.cellText, styles.cellTextLevel1, styles.recommendedCellText]}>
                100%
              </Text>
            </View>
          )}
          {benchmark && (
            <View style={[styles.cell, { marginRight: 0 }]}>
              <Text style={[styles.cellText, styles.cellTextLevel1]}>100%</Text>
            </View>
          )}
        </View>
      </View>

      {target.tax_status_distribution && (
        <View style={styles.table}>
          {Object.entries(target.tax_status_distribution)
            .filter(([, weight]) => !!weight)
            .map(([taxStatus, weight]) => {
              const taxLabel = TAX_OPTIONS.find(tax => tax.value === taxStatus);
              if (!taxLabel) return null;
              return (
                <View key={taxStatus} style={[styles.row, styles.taxRow]} wrap={false}>
                  <View style={styles.cell}>
                    <Text style={styles.taxCellText}>{taxLabel.label}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={[styles.cellText, styles.taxCellText]}>
                      {setPrecision(weight * 100, 2)}%
                    </Text>
                  </View>
                  {recommended && (
                    <View style={[styles.cell, styles.recommendedCell]}>
                      <View style={[styles.cellText, styles.taxCellText]} />
                      <Text style={[styles.cellText, styles.taxCellText]}>-</Text>
                    </View>
                  )}
                  {benchmark && (
                    <View style={[styles.cell, { marginRight: 0 }]}>
                      <Text style={[styles.cellText, styles.taxCellText]}>-</Text>
                    </View>
                  )}
                </View>
              );
            })}
        </View>
      )}
    </View>
  );
};

DistributionSummary.propTypes = {
  benchmark: PropTypes.object,
  benchmarkName: PropTypes.string,
  proposal: PropTypes.shape(ProposalPropTypes),
  proposalType: PropTypes.string,
  recommended: PropTypes.object,
  recommendedName: PropTypes.string,
  start: PropTypes.string,
  startingValue: PropTypes.number,
  target: PropTypes.object,
  targetName: PropTypes.string
};

DistributionSummary.defaultProps = {
  benchmark: null,
  benchmarkName: '',
  proposal: null,
  proposalType: ACCOUNT_PROPOSAL_TYPE,
  recommended: null,
  recommendedName: '',
  start: null,
  startingValue: null,
  target: null,
  targetName: ''
};

export default DistributionSummary;
