/* globals DISPLAY_DATE_FORMAT, FULL_DATE_FORMAT */
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AutosizeInput from 'components/autosize-input';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import SimpleSpinner from 'components/utils/simple-spinner';
import './styles.scss';

const ENTER_KEY = 'Enter';
const ESCAPE_KEY = 'Escape';

export const NOTE_LAYOUTS = {
  SMALL: 'small',
  MODAL: 'extended',
  COMPLETE: 'complete'
};

const NotesList = ({ layout, notes, onSuccess, target, withAccounts }) => {
  const [editMode, setEditMode] = useState({});
  const [inlineNote, setInlineNote] = useState('');
  const [loading, setLoading] = useState(true);

  const { accountProvider, investorProvider, prospectProvider } = useContext(AdvisorContext);

  const withNotes = notes && notes.length > 0;

  useEffect(() => {
    const fetchNotes = async () => {
      if (target?.investor_id) await accountProvider.listNotes(target);
      else if (target.is_prospect) await prospectProvider.listNotes(target);
      else await investorProvider.listNotes(target);
      if (withAccounts)
        await Promise.all(
          withAccounts.map(async id => {
            await accountProvider.listNotes({ id });
          })
        );
      setLoading(false);
    };

    fetchNotes();
  }, []);

  const handleChangeInline = event => {
    setInlineNote(event.target.value);
  };

  const discardNoteChanges = () => {
    setEditMode({});
    setInlineNote('');
  };

  const saveNoteChanges = ({ target, id }) => {
    const providers = {
      account: accountProvider,
      investor: investorProvider,
      prospect: prospectProvider
    };
    const provider = providers[target.type];

    if (!provider) return;

    if (inlineNote === '') provider.removeNote(target, id);
    else
      provider.editNote(target, id, inlineNote).then(() => {
        discardNoteChanges();
        onSuccess();
      });
  };

  const editNote = (id, message) => {
    setEditMode({ [id]: true });
    setInlineNote(message);
  };

  return (
    <div id="AccountNotesList" className={`layout ${layout}`}>
      {loading && (
        <div className="loading-notes">
          <SimpleSpinner size="25" />
        </div>
      )}

      {!loading && !withNotes && <p className="no-notes">No notes</p>}

      {!loading && withNotes && (
        <div className="historical">
          {notes.map(note => (
            <div key={note.id} className="row">
              <div className="date" title={moment(note.created).format(FULL_DATE_FORMAT)}>
                <span>{moment(note.created).format(DISPLAY_DATE_FORMAT)}</span>
                {layout === NOTE_LAYOUTS.MODAL && (
                  <span className="header-author">by {note.author || 'System'}</span>
                )}
                {layout !== NOTE_LAYOUTS.MODAL && (
                  <div>
                    <Link
                      className="name"
                      to={
                        note.target.type === 'account'
                          ? `/advisor/investors/${target.id}/account/${note.target.id}`
                          : `/advisor/investors/${target.id}`
                      }
                    >
                      {note.target.name}
                    </Link>
                  </div>
                )}
              </div>

              {editMode[note.id] ? (
                <AutosizeInput
                  autoFocus
                  value={inlineNote}
                  name="inline-field"
                  id="autosizeInput-inline-field"
                  className="note-inline-field"
                  onChange={handleChangeInline}
                  onKeyUp={e => {
                    if (e.key === ENTER_KEY) saveNoteChanges(note);
                    if (e.key === ESCAPE_KEY) discardNoteChanges();
                  }}
                />
              ) : (
                <p>
                  {note.message}
                  {note.modified.substring(0, 22) !== note.created.substring(0, 22) && (
                    <small title={moment(note.modified).format(FULL_DATE_FORMAT)}>
                      (edited {moment(note.modified).format(DISPLAY_DATE_FORMAT)})
                    </small>
                  )}
                  {layout !== NOTE_LAYOUTS.MODAL && (
                    <span className="author">generated by {note.author || 'System'}</span>
                  )}
                </p>
              )}

              <div className="right-col">
                {editMode[note.id] ? (
                  <div className="edit-actions">
                    <button
                      type="button"
                      className="btn-save btn-transparent"
                      onClick={() => {
                        saveNoteChanges(note);
                      }}
                    >
                      <img className="icon" src="/img/checkmark_filled_blue.svg" alt="save" />
                    </button>
                    <button
                      type="button"
                      className="btn-discard btn-transparent"
                      onClick={() => {
                        discardNoteChanges();
                      }}
                    >
                      <img className="icon" src="/img/cancel_round.svg" alt="discard" />
                    </button>
                  </div>
                ) : (
                  note.author &&
                  !note.system_message && (
                    <button
                      type="button"
                      className="btn btn-transparent"
                      onClick={() => {
                        editNote(note.id, note.message);
                      }}
                    >
                      <i className="fs-icon-edit-icon-blue" />
                    </button>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { target, withAccounts } = ownProps;
  const { accountNotes, investorNotes, prospectNotes } = stateProps;

  let notes = [];

  if (target?.investor_id) {
    if (accountNotes && !_.isEmpty(accountNotes)) notes.push(accountNotes[target.id]);
  } else if (target.is_prospect) {
    if (prospectNotes && !_.isEmpty(prospectNotes)) notes.push(prospectNotes[target.id]);
  } else if (investorNotes && !_.isEmpty(investorNotes)) notes.push(investorNotes[target.id]);

  if (withAccounts && accountNotes && !_.isEmpty(accountNotes))
    withAccounts.forEach(id => {
      notes.push(accountNotes[id]);
    });

  notes = _.orderBy(notes.flat().filter(note => !!note), ['created'], ['desc']);

  return {
    ...stateProps,
    ...ownProps,
    notes
  };
};

NotesList.propTypes = {
  layout: PropTypes.string,
  notes: PropTypes.array,
  onSuccess: PropTypes.func,
  target: PropTypes.object.isRequired,
  withAccounts: PropTypes.array
};

NotesList.defaultProps = {
  layout: NOTE_LAYOUTS.SMALL,
  notes: [],
  onSuccess: () => {},
  withAccounts: null
};

export default connect(
  state => ({
    accountNotes: state.accounts.notes,
    investorNotes: state.investors.notes,
    prospectNotes: state.prospects.notes
  }),
  null,
  mergeProps
)(NotesList);
