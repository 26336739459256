/* eslint-disable no-underscore-dangle */
import cn from 'classnames';
import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis';
import { TAX_OPTIONS } from 'constants/accounts';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getPositionsTaxonomy, getTaxonomyLevelIterator, setPrecision } from 'utils/utils';
import {
  BENCHMARK_POSITIONS_ANALYSIS_CHART_ID,
  RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID,
  TARGET_POSITIONS_ANALYSIS_CHART_ID
} from './constants';
import Row from './row';
import './styles.scss';
import { buildCollapsibleMap, getLevelPercent, processTaxonomy } from './utils';

const POSITION_CHART_STYLE = {
  width: '150px',
  height: '120px'
};

const POSITION_CHART_OPTIONS = {
  radius: '40%',
  position: 'absolute'
};

const DistributionSummary = ({
  benchmark,
  benchmarkName,
  isDraft,
  onChartReady,
  recommended,
  recommendedName,
  setCollapsedHoldings,
  start,
  startingValue,
  target,
  targetName,
  taxonomies
}) => {
  const {
    user: {
      advisor: {
        company: {
          expand_asset_classification: expandAssetClassification,
          expand_asset_details: expandAssetDetails
        }
      }
    }
  } = useContext(AdvisorContext);

  const [collapsibleMap, setCollapsibleMap] = useState({});

  const collapsedHoldings = Object.keys(collapsibleMap).reduce((acc, key) => {
    if (collapsibleMap[key]) return [...acc, key];
    return acc;
  }, []);

  const targetTaxonomy = getPositionsTaxonomy(
    target,
    taxonomies,
    expandAssetClassification,
    expandAssetDetails
  );
  const benchmarkTaxonomy = benchmark
    ? getPositionsTaxonomy(benchmark, taxonomies, expandAssetClassification, expandAssetDetails)
    : {};
  const recommendedTaxonomy = recommended
    ? getPositionsTaxonomy(recommended, taxonomies, expandAssetClassification, expandAssetDetails)
    : {};

  const holdingsTable = {};
  processTaxonomy('target', targetTaxonomy, holdingsTable);
  if (benchmark) processTaxonomy('benchmark', benchmarkTaxonomy, holdingsTable);
  if (recommended) processTaxonomy('recommended', recommendedTaxonomy, holdingsTable);

  const toggleRow = id => {
    setCollapsibleMap(prevCollapsibleMap => ({
      ...prevCollapsibleMap,
      [id]: !prevCollapsibleMap[id]
    }));
  };

  useEffect(() => {
    setCollapsibleMap(buildCollapsibleMap(holdingsTable));
  }, [JSON.stringify(holdingsTable)]);

  useEffect(() => {
    setCollapsedHoldings(collapsedHoldings);
  }, [JSON.stringify(collapsedHoldings)]);

  const targetValue = targetTaxonomy.__value ?? 0;
  const recommendedValue = recommendedTaxonomy.__value ?? 0;
  const benchmarkValue = benchmarkTaxonomy.__value ?? 0;

  return (
    <div id="distribution-summary">
      <DateValue proposal={{ start, starting_value: startingValue }} />

      <div
        className={cn('fancy-grid', 'holdings', {
          'no-recommended': !recommended,
          'no-benchmark': !benchmark
        })}
      >
        <div className="transparent" />
        {target && <div className="header">{targetName || 'Current Portfolio'}</div>}
        {recommended && <div className="header">{recommendedName}</div>}
        {benchmark && <div className="header">{benchmarkName}</div>}

        <div className="transparent" />
        {target && (
          <div className="positions-chart target">
            <div className="use-portfolio-container">
              <PositionsAnalysis
                id={TARGET_POSITIONS_ANALYSIS_CHART_ID}
                layout="box"
                onChartReady={onChartReady}
                portfolio={target}
                positionChartOptions={POSITION_CHART_OPTIONS}
                positionChartStyle={POSITION_CHART_STYLE}
              />
            </div>
          </div>
        )}
        {recommended && (
          <div className="positions-chart recommended">
            <div className="use-portfolio-container">
              <PositionsAnalysis
                id={RECOMMENDED_POSITIONS_ANALYSIS_CHART_ID}
                layout="box"
                onChartReady={onChartReady}
                portfolio={recommended}
                positionChartOptions={POSITION_CHART_OPTIONS}
                positionChartStyle={POSITION_CHART_STYLE}
              />
            </div>
          </div>
        )}
        {benchmark && (
          <div className="positions-chart benchmark">
            <div className="use-portfolio-container">
              <PositionsAnalysis
                id={BENCHMARK_POSITIONS_ANALYSIS_CHART_ID}
                layout="box"
                onChartReady={onChartReady}
                portfolio={benchmark}
                positionChartOptions={POSITION_CHART_OPTIONS}
                positionChartStyle={POSITION_CHART_STYLE}
              />
            </div>
          </div>
        )}
      </div>

      <div className="holding-table">
        {getTaxonomyLevelIterator(holdingsTable).map(([l1Key, l1], l1Idx) => (
          <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
            <Row
              label={l1Key}
              className="row-title depth-1"
              collapsed={expandAssetClassification && collapsibleMap[l1Key]}
              toggleRow={expandAssetClassification ? toggleRow : null}
              target={getLevelPercent(l1.__target_value, targetValue)}
              benchmark={benchmark ? getLevelPercent(l1.__benchmark_value, benchmarkValue) : null}
              recommended={
                recommended ? getLevelPercent(l1.__recommended_value, recommendedValue) : null
              }
            />

            {expandAssetClassification &&
              !collapsibleMap[l1Key] &&
              getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                  <Row
                    label={l2Key}
                    className="depth-2"
                    collapsed={expandAssetDetails && collapsibleMap[l2Key]}
                    toggleRow={expandAssetDetails ? toggleRow : null}
                    target={getLevelPercent(l2.__target_value, targetValue)}
                    benchmark={
                      benchmark ? getLevelPercent(l2.__benchmark_value, benchmarkValue) : null
                    }
                    recommended={
                      recommended ? getLevelPercent(l2.__recommended_value, recommendedValue) : null
                    }
                  />

                  {expandAssetDetails &&
                    !collapsibleMap[l2Key] &&
                    getTaxonomyLevelIterator(l2).map(([l3Key, l3], l3Idx) => (
                      <Fragment key={`l3-${l3.__id ?? `${l1Idx}-${l2Idx}-${l3Idx}`}`}>
                        <Row
                          label={l3Key}
                          className="depth-3"
                          target={getLevelPercent(l3.__target_value, targetValue)}
                          benchmark={
                            benchmark ? getLevelPercent(l3.__benchmark_value, benchmarkValue) : null
                          }
                          recommended={
                            recommended
                              ? getLevelPercent(l3.__recommended_value, recommendedValue)
                              : null
                          }
                        />
                      </Fragment>
                    ))}
                </Fragment>
              ))}
          </Fragment>
        ))}

        <div className="row depth-1 row-total">
          <div className="col category">Total Portfolio</div>
          <div className="col">100%</div>
          {recommended && (
            <div className="col">
              <div className="subcol diff" />
              <div className="subcol">100%</div>
            </div>
          )}
          {benchmark && <div className="col">100%</div>}
        </div>

        {target.tax_status_distribution && <br />}

        {target.tax_status_distribution &&
          Object.entries(target.tax_status_distribution)
            .filter(([, weight]) => !!weight)
            .map(([taxStatus, weight]) => {
              const taxLabel = TAX_OPTIONS.find(tax => tax.value === taxStatus);
              if (!taxLabel) return null;
              return (
                <div className="row row-taxes depth-1" key={taxStatus}>
                  <div className="col category">{taxLabel.label}</div>
                  <div className="col">{setPrecision(weight * 100, 2)}%</div>
                  {recommended && (
                    <div className="col">
                      <div className="subcol diff" />
                      <div className="subcol">-</div>
                    </div>
                  )}
                  {benchmark && <div className="col">-</div>}
                </div>
              );
            })}
      </div>
    </div>
  );
};

DistributionSummary.propTypes = {
  benchmark: PropTypes.object,
  benchmarkName: PropTypes.string,
  isDraft: PropTypes.bool,
  onChartReady: PropTypes.func,
  recommended: PropTypes.object,
  recommendedName: PropTypes.string,
  setCollapsedHoldings: PropTypes.func,
  start: PropTypes.string,
  startingValue: PropTypes.number,
  target: PropTypes.object,
  targetName: PropTypes.string,
  taxonomies: PropTypes.array.isRequired
};

DistributionSummary.defaultProps = {
  benchmark: null,
  benchmarkName: '',
  isDraft: false,
  onChartReady: null,
  recommended: null,
  recommendedName: '',
  setCollapsedHoldings: () => {},
  start: null,
  startingValue: null,
  target: null,
  targetName: ''
};

export default connect(state => ({
  taxonomies: state.market.taxonomies
}))(DistributionSummary);
